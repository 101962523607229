import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/volkswagen-gti',
    name: 'GTI',
    component: () => import(/* webpackChunkName: "GTI" */ '../views/Gti.vue')
  },
  {
    path: '/volkswagen-arteon-r',
    name: 'Arteon',
    component: () => import(/* webpackChunkName: "Arteon" */ '../views/Arteon.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
